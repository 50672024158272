import React, { useState } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [display, setDisplay] = useState(true);
  return (
    <div className="navBar">
      <Link to="/" className="Link">
        <h1 className={display ? "headerName" : "headerName headerName-NX"}>
          RAYLENE AMOURE
        </h1>
      </Link>
      <div className={display ? "toggleBar" : "toggleBar toggleBar-NX"}>
        <Link to="/introduction" className="Link">
          <h3>Introduction</h3>
        </Link>
        <Link to="/visuals" className="Link">
          <h3>Private Channel</h3>
        </Link>
        <Link to="/booking" className="Link">
          <h3>Bookings</h3>
        </Link>
        <Link to="/service" className="Link">
          <h3>Online Services</h3>
        </Link>
        {/* <Link to="/testimonial" className="Link">
          <h3>Testimonials</h3>
        </Link> */}
        <Link to="/spoilme" className="Link">
        <h3>Spoil Me</h3>
        </Link>
        <Link to="/meet" className="Link">
          <h3>Meet</h3>
        </Link>
      </div>
      <div
        className={
          !display ? "hamburger hamburger-in" : "hamburger hamburger-out"
        }
        onClick={() => setDisplay(!display)}
      >
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

export default Navbar;
