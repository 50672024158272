import React from "react";
import Navbar from "../../components/Navbar";
import serviceImg from "../../img/online1.jpg";
import "./Service.css";

const Service = () => {
  return (
    <div>
      <div className="Navs">
        <Navbar />
      </div>
      <div className="Service">
        <img src={serviceImg} alt="" className="serviceImg" />
        <div className="serviceBox">
          <h1>ONLINE SERVICES</h1>
          <p>Texting - starts at $50 for 30 minutes</p>
          <p>Phone call - $100</p>
          <p>Video call:</p>
          <ul className="serviceList">
            <li>10 minutes - $75 (this is clothed)</li>
            <li>15 minutes - $100</li>
            <li>30 minutes - $150</li>
          </ul>
          <p>
            Virtual GFE - $200 per day. Includes texting, voice messages, and
            photos/videos sent privately just for your eyes only!
          </p>
          <p>Texting package - $500 per week or $1500 per month.</p>
          <p>
            I also sell personal items. Please contact me directly to discuss.
          </p>
        </div>
      </div>
      <div className="footerLine"></div>
    </div>
  );
};

export default Service;
