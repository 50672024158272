import React from "react";
import "./Footer.css";
import { BiLogoTiktok, BiLogoGmail } from "react-icons/bi";
import insta from "../img/Instagram.svg"
import gmail from "../img/Gmail.svg"
import face from "../img/Facebook.svg"
import tiktok from "../img/TikTok.svg"

const Footer = () => {
  return (
    <div className="footer">
      <div>
        <h4 className="ray">RAYLENE AMOURE</h4>
        <h5 className="rayContents">Independent Companion & Content Curator</h5>
        <p className="rayContent">Los Angeles, California & FMTY</p>
      </div>
      <div className="logoDiv">
        <a
          href="https://www.tiktok.com/@thisisrayleneamoure?_t=8hNPQYhvL91&_r=1"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={tiktok} alt=""  className="footerIcons"/>
        </a>
        <a
          href="mailto:thisisraylene@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
        >
        
          <img src={gmail} alt=""  className="footerIcons"/>
        </a>
        <a
          href="https://www.facebook.com/profile.php?id=61553759360168&mibextid=LQQJ4d"
          target="_blank"
          rel="noopener noreferrer"
        >
            <img src={face} alt=""  className="footerIcons"/>
        </a>
        <a
          href="https://instagram.com/thisisrayleneamoure?igshid=eHQ5bGpndDduMDB5&utm_source=qr"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={insta} alt="" className="footerIcons"/>
        </a>
    
      </div>
    </div>
  );
};

export default Footer;
