import React from "react";
import "./Spoilme.css";
import Navbar from "../../components/Navbar";
import spoilImg from "../../img/visuals.jpg";

const Spoilme = () => {
  return (
    <div>
      <div className="Navs">
        <Navbar />
      </div>
      <div className="spoilBox">
        <h1 className="spoilTxt">SPOIL ME</h1>
        <div className="spoil">
          <img src={spoilImg} alt="" className="spoilImg" />
          <div className="spoilP">
            <p>While it is never expected, gifts are always appreciated. </p>
            <p>Here are some of my favourite things:</p>
            <p>
              <a
                href="https://www.apple.com/shop/buy-giftcard/giftcard"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p id="amazonP">Apple Gift Card</p>
              </a>
            </p>
            <p>Send to my Email: thisisraylene@gmail.com</p>
            <p>Cash or EMT tips are also accepted.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Spoilme;
