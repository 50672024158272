import React, { useRef } from "react";
import "./Meet.css";
import Navbar from "../../components/Navbar";
import emailjs from "@emailjs/browser";

const Meet = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_6miiekl",
        "template_x0bpg6q",
        form.current,
        "doh-13U1QwulasdFy"
      )
      .then(
        (result) => {
          console.log(result.text);
           window.location.reload();
        },
        (error) => {
          console.log(error.text);
        }
      );
      // window.location.reload();
  };
  return (
    <div>
      <div className="Navs">
        <Navbar />
      </div>
      <div className="Meet">
        <div className="meetContact">
          <h1>CONTACT CALISTA</h1>
          <p>meetcalistadiamandis@outlook.com</p>
          <p className="meetP">Fresno, California</p>
          <p className="meetP">
            For screening I require a photo ID and a selfie. An active LinkedIn
            profile is also an option.
          </p>
        </div>
        <div className="meetBox2">
          <form ref={form} onSubmit={sendEmail}>
            <div className="inputBox">
              <label htmlFor="user_name">Name (required)</label>
              <input
                type="text"
                id="user_name"
                name="user_name"
                className="user_name"
                required
              />
            </div>

            <div className="inputBox">
              <label htmlFor="user_age">Age (required)</label>
              <input
                type="text"
                id="user_age"
                className="user_age"
                required
                name="user_age"
              />
            </div>
            <div className="inputBox">
              <label htmlFor="user_phone">Phone</label>
              <input
                type="text"
                id="user_phone"
                className="user_phone"
                required
                name="user_phone"
              />
            </div>
            <div className="inputBox">
              <label htmlFor="user_email">Email (required)</label>
              <input
                type="text"
                id="user_email"
                className="user_email"
                required
                name="user_email"
              />
            </div>
            <div className="inputBox">
              <label htmlFor="user_date">Date (required)</label>
              <input
                type="date"
                id="user_date"
                className="user_date"
                required
                name="user_date"
              />
            </div>
            <div className="inputBox">
              <label htmlFor="user_lengthofdate">
                Length of Date (required)
              </label>
              <input
                type="text"
                placeholder="1 hour, 3 hours, FMTY"
                id="user_lengthofdate"
                className="user_lengthofdate"
                required
                name="user_lengthofdate"
              />
            </div>
            <div className="inputBox">
              <label htmlFor="user_cityandstate">
                City and state of residence (required)
              </label>
              <input
                type="text"
                id="user_cityandstate"
                className="user_cityandstate"
                required
                name="user_cityandstate"
              />
            </div>
            <div className="inputBox">
              <label htmlFor="user_inorout">
                Incall or Outcall? (required)
              </label>
              <input
                type="text"
                id="user_inorout"
                className="user_inorout"
                required
                name="user_inorout"
              />
            </div>
            <div className="inputBox">
              <label htmlFor="user_citytomeet">
                Which city do you want to meet? (required)
              </label>
              <input
                type="text"
                id="user_citytomeet"
                className="user_citytomeet"
                required
                name="user_citytomeet"
              />
            </div>
            <div className="inputBox">
              <label htmlFor="user_findme">Where did you find me?</label>
              <input
                type="text"
                placeholder="Tiktok, Ad Site, etc"
                id="user_findme"
                className="user_findme"
                required
                name="user_findme"
              />
            </div>
            <div className="inputBox">
              <label htmlFor="user_meet">Meet</label>
              <textarea
                id="user_meet"
                className="user_meet"
                cols="30"
                rows="10"
                name="user_meet"
              ></textarea>
            </div>
            <button className="meetBtn">Send</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Meet;
