import React from "react";
import "./Booking.css";
import Navbar from "../../components/Navbar";
import booking1 from "../../img/booking1.jpg";
import booking2 from "../../img/booking2.jpg";

const Booking = () => {
  return (
    <div>
      <div className="Navs">
        <Navbar />
      </div>
      <div className="booKing">
        <div className="bookingBox1">
          <img src={booking1} alt="" className="bookImg" />
          <div className="bookingBox">
            <h2>USA GFE RATES</h2>
            <h6>I am available for incall and outcall. Currency is USD.</h6>
            <p>60 min $200</p>
            <p>90 min $300</p>
            <p>2 hours $400 (preferred minimum)</p>
            <p>3 hours $500</p>
            <p>4 hours $600</p>
            <p>6-8 hours $800</p>
            <p>Overnight $1200 (8-12 hours)</p>
            <p>24 hours $1500</p>
            <p>PSE +$200</p>
            <p>OF Filming +$200</p>
            <p className="bookingP">
              *I also offer a social outing date for $200/hr*
            </p>
          </div>
        </div>
        <div className="bookingBox1 box2">
          <img src={booking2} alt="" className="bookImg" />
          <div className="bookingBox">
            <h2>FMTY GFE RATES</h2>
            <h6>Please note: these rates do not include airfare & hotel. </h6>
            <h6>
              This is ONLY within United States, Canada or international.
            </h6>
            <p>4-6 hours $1000</p>
            <p>8-12 hours $1500</p>
            <p>24 hours $2000</p>
            <p>3 days $3000</p>
            <p>PSE +$200</p>
            <p>OF Filming $200</p>
            <p className="bookingP">
              *Sponsoring a trip is another option for those that can’t quite
              afford a FMTY that can be discussed*
            </p>
          </div>
        </div>
        <div className="finePrint">
          <h1 className="printTxt">THE FINE PRINT</h1>
          <ul className="printList">
            <li className="list1">Fly Me To You</li>
            <li className="list2">
              A $200 deposit must be sent before we move forward in making
              plans. This will be deducted from the overall cost.
            </li>
            <li className="list2">
              50% deposit is required to confirm booking. Inquire via email for
              further details.
            </li>
            <li className="list1">Time Commitments</li>
            <li className="list2">
              Dates 4 hours or longer must include social time. Need ideas? Just
              ask!
            </li>
            <li className="list2">
              Overnights & 24 hours or more dates require 7 hours of
              uninterrupted sleep.
            </li>
            <li className="list1">Deposit Details</li>
            <p>
              A 25% deposit is required to confirm our time together payable via
              e-transfer or PayPal.
            </p>
            <li className="list2">
              Remaining envelope minus deposit must be provided in cash at the
              start of our date. Digital options are not accepted unless agreed
              upon before your arrival. Surcharge may apply.
            </li>
            <li className="list1">Cancellation & Rescheduling</li>
            <li className="list2">
              I understand conflicts happen. Please notify me as soon as
              possible if you need to cancel or reschedule our date.{" "}
              <span>
                Deposit will go towards our rescheduled date or forfeited if you
                choose to cancel.
              </span>
            </li>
            <li className="list2">
              Same day cancellations: FULL amount is due. This is required.
            </li>
            <li className="list2">
              In the event I cancel, your deposit will be refunded in full.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Booking;
