import React from "react";
import Navbar from "../../components/Navbar";
import "./Introduction.css";
import intro1 from "../../img/intro1.jpg";
import intro2 from "../../img/intro2.jpg";
import ReactPlayer from "react-player";

const Introduction = () => {
  return (
    <div>
      <div className="introductionPage">
        {/* <div className="Nav"> */}
        <Navbar />
        {/* </div> */}
      </div>
      <div className="introBox">
        <h2 className="introHead">RAYLENE AMOURE - your bbw greek goddess</h2>
        <div className="introBox1">
          <img src={intro1} alt="" className="disImg" />
          <div>
            <h6 className="introWelcome">
              Welcome, welcome, the party's only just begun!
            </h6>
            <p className="introWelcomeText">
              Sure to please all of your senses; Raylene has been described as
              sultry, easy going, and fun. All-natural voluptuous curves, tanned
              skin, and seductive eyes will have your heart racing and blood
              rushing from the moment you meet. Never one to disappoint,
              Raylene's outgoing, confident personality and exhilarating spirit
              guarantee a date you'll never forget. Her passion for sports,
              music, and adventure, mixed with a calming presence and open mind,
              ensure there's never a shortage of things to discuss.
            </p>
            <p className="introWelcomeText">
              Providing companion services to people from all walks of life,
              Raylene enjoys the finer things - good food, good company, and
              most importantly: a good time. Her seductive bedroom eyes,
              flirtatious personality, voluptuous curves and sexual nature will
              draw you in and have you hooked before you know it and craving
              more from the moment you leave.
            </p>
          </div>
        </div>
        <div className="introBox2">
          <img src={intro2} alt="" className="disImg" />
          <div className="moreAbout">
            <h2 className="aboutH2">More About RAYLENE</h2>
            <h6 className="aboutH6">
              Height: <span>5'6</span>
            </h6>
            <h6 className="aboutH6">
              Age: <span>34</span>
            </h6>
            <h6 className="aboutH6">
              Hair Color: <span>Blonde currently</span>
            </h6>
            <h6 className="aboutH6">
              Eye Color: <span>Brown</span>
            </h6>
            <h6 className="aboutH6">
              Ethnicity: <span>Greek</span>
            </h6>
            <h6 className="aboutH6">
              Measurements: <span>38M Nautral</span>
            </h6>
            <h6 className="aboutH6">
              Hobbies/Interests:{" "}
              <span>
                Traveling, sports, being outdoors, concerts, going out for
                dinner and drinks, dancing, movie nights in, & modeling
                part-time.
              </span>
            </h6>
            <h6 className="aboutH6">
              Favorite cuisines: <span>Greek & Italian</span>
            </h6>
            <h6 className="aboutH6">
              Favorite drinks: <span>Prosecco, tequila, & caesars</span>
            </h6>
            <h6 className="aboutH6">
              Favorite flowers:{" "}
              <span>Roses or anything colourful that’s pretty</span>
            </h6>
            <h6 className="aboutH6">
              Favorite color: <span>Red or Royal Blue</span>
            </h6>
            <h6 className="aboutH6">
              My dream date would be:{" "}
              <span>A beach getaway somewhere I haven’t been yet!</span>
            </h6>
          </div>
        </div>
        <div className="reactPlayer">
          <ReactPlayer
            url={require("../../img/instasave.MP4")}
            controls
            width="100%"
            height="360px"
          />
        </div>
      </div>
    </div>
  );
};

export default Introduction;
