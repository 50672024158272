import React from "react";
import "./Testimonials.css";
import Navbar from "../../components/Navbar";

const Testimonials = () => {
  return (
    <div>
      <div className="Navs">
        <Navbar />
      </div>
      <div className="Testimonial">
        <h1 className="testTxt">TESTIMONIALS</h1>
        <div className="testimonialBox">
          <div className="testBox">
            <h3 className="Mr">MR. T</h3>
            <p className="testP">
              A pleasure to the eyes, she looks even sexier in person. She was
              very welcoming and hospitable; refreshments were offered on
              arrival. Also, she's very attentive and easy to communicate with.
              Hygiene is quite important to me and she smelled amazing! Her body
              is stunning, soft, and curvaceous. Head game was A1! Her sexy
              round ass and juicy tits had me on go mode throughout the entire
              session. She never once mentioned the time as well. I'm awaiting
              future opportunities to enjoy her company.
            </p>
          </div>
          <div className="testBox">
            <h3>MR. K</h3>
            <p className="testP">
              “Calista is not just down to earth, she's absolutely captivating.
              Spending time with her is like entering the enchanting realm of
              the Calista Experience. From the moment you lay eyes on her, she
              effortlessly puts you at ease, and trust me, the laughter is
              endless. She's not just well rounded, she's the embodiment of
              authenticity. Being with her feels like reconnecting with an old
              flame, effortlessly picking up where you left off. In private, she
              can match your energy, leaving you breathless, or she can
              playfully outshine you, leaving you spinning in circles. But no
              matter what you choose, one thing's for certain: she will leave an
              indelible mark on your heart.“
            </p>
          </div>
          <div className="testBox">
            <h3>MR. J</h3>
            <p className="testP">
              “I wanted to thank you for an amazing time. You’re a gorgeous
              woman with curves for days and a stunning personality. You quickly
              eased my nerves and made me feel comfortable. I wanted to thank
              you for our time together. You made me smile while managing to
              suck the dick right off of my body. Thanks again for the passion
              and the horny memories. You left me craving more! Only regret is
              not booking more time with you.”
            </p>
          </div>
          <div className="testBox">
            <h3>MR. M</h3>
            <p className="testP">
              “I had the pleasure of seeing Calista on her most recent visit to
              Winnipeg. She was really prompt with her responses and inquiries.
              I booked her for a one hour GFE appointment and now regret not
              booking her for longer. From the moment I walked in the door until
              I left it was incredibly amazing! She is even more beautiful in
              person than in her photos and so engaging; it was like we had
              known each other for years and the passion she exhibited during
              our entire time together was nothing like I had ever experienced
              before. The entire hour I felt like I was the most important
              person in her life, sharing amazing sex, witty conversation, and a
              comfortness I had never felt before. I wouldn't call her a SP
              (service provider), the better description would be Experience
              Provider. I cannot wait for her next visit, and may change my
              travel schedule to visit her again in the GTA.”
            </p>
          </div>
          <div className="testBox">
            <h3>MR. L</h3>
            <p className="testP">
              “From the moment I got to the door and it opened to Calista
              Diamondis my soul knew I was about to be in for such a dreamy date
              come true and Im soo happy we changed our date longer because she
              is such a sweet, loving, and down to earth person to meet. Getting
              to know each other I enjoyed talking with her soo much we enjoy so
              much of the same stuff we talked for hours as I gazed over such a
              beauty in front of me, trying to not get lost in my words and
              fumble them out cause she's just pure stunning in person. After we
              moved into the bedroom and let me just say the talents she post's
              are true to life Goddess Calista she's such a pleasure to be with.
              Moving on with our date more we had a very lovely dinner out where
              she was even more Stunning!! We watched a movie afterwards back in
              her room where we cuddled the night away in each other's arms
              reliving our afternoon.”
            </p>
          </div>
          <div className="testBox">
            <h3>MR. P</h3>
            <p className="testP">
              "I had the pleasure of meeting Calista during my last visit to
              Toronto earlier this year and I still find myself day dreaming
              about our date. I was a bit anxious about meeting but she was so
              warm, playful and open that those feelings were instantly washed
              away and replaced with euphoria and bliss. I had an amazing time
              and if you follow her social media or video content just know that
              her pics and vids don't capture the full scope of her beauty. If
              you're lucky enough to spend time with this Goddess of a woman
              treat her well as she's a treasure❤️"
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
