import React from "react";
import "./Free.css";
import Navbar from "../../components/Navbar";
import private1 from "../../img/private1.jpg";
import private2 from "../../img/private2.jpg";
import private3 from "../../img/private3.jpg";
import private4 from "../../img/private4.jpg";
import private5 from "../../img/private5.jpg";
import private6 from "../../img/private6.jpg";

const Free = () => {
  return (
    <div>
      <div className="Navs">
        <Navbar />
      </div>
      <div className="imgBox">
        <img src={private1} alt="" />
        <img src={private2} alt="" />
        <img src={private3} alt="" />
        <img src={private4} alt="" />
        <img src={private5} alt="" />
        <img src={private6} alt="" />
      </div>
    </div>
  );
};

export default Free;
