import Navbar from "./components/Navbar";
import "./App.css";
import LandingPage from "./pages/landing/LandingPage";
import Footer from "./components/Footer";
import Introduction from "./pages/introduction/Introduction";
import Booking from "./pages/booking/Booking";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Service from "./pages/onlineServices/Service";
import Testimonials from "./pages/testimonials/Testimonials";
import Layout from "./components/Layout";
import Meet from "./pages/meet/Meet";
import Visuals from "./pages/visuals/Visuals";
import Free from "./pages/visuals/Free";
import Spoilme from "./pages/spoilme/Spoilme";
import Vip from "./pages/visuals/Vip";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<LandingPage />} />
          <Route path="/introduction" element={<Introduction />} />
          <Route path="/booking" element={<Booking />} />
          <Route path="/service" element={<Service />} />
          <Route path="/testimonial" element={<Testimonials />} />
          <Route path="/meet" element={<Meet />} />
          <Route path="/visuals" element={<Visuals />} />
          <Route path="/free" element={<Free />} />
          <Route path="/spoilme" element={<Spoilme />} />
          <Route path="/vip" element={<Vip />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
