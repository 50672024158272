import React, { useState } from "react";
import Navbar from "../../components/Navbar";
import "./Vip.css";
import insta from "../../img/Instagram.svg";
import tiktok from "../../img/TikTok.svg";
import face from "../../img/Facebook.svg";
import gmail from "../../img/Gmail.svg";

const Vip = () => {
  const [inputValue, setInputValue] = useState("");
  const [telegramLink, setTelegramLink] = useState("");
  const [error, setError] = useState("");

  //   const handleButtonClick = async () => {
  //     try {
  //       const response = await fetch("https://profile-r0el.onrender.com/api/generate-link", {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({ inputValue }),
  //       });

  //       const data = await response.json();

  //       if (data.success) {
  //         // If success, set the Telegram link
  //         setTelegramLink(data.telegramLink);
  //         setError("");
  //       } else {
  //         // If not success, display an error message
  //         setError(data.message);
  //         setTelegramLink("");
  //       }
  //     } catch (error) {
  //       console.error("Error:", error);
  //       setError("An error occurred. Please try again.");
  //       setTelegramLink("");
  //     }
  //   };

  const handleButtonClick = () => {
    // Check if the input value is 'raylene'
    if (inputValue.trim().toLowerCase() === "raylene0x39") {
      window.open("https://t.me/+egff9xi5onlkZjYx", "_blank");
      setError("");
    } else {
      // If not, show a message (you can replace this with any other action)
      //   alert('Access code not correct.');
      setError("Access code not correct.");
    }
  };
  return (
    <div>
      <div className="Navs">
        <Navbar />
      </div>
      <div className="Vip">
        <p className="vipP">Are 42HH titties big enough for you daddy 😏?</p>
        <p className="vipP">
          Could i be your red head, southern, curvy girlfriend hun 🥺
        </p>
        <p className="vipP">
          Message me privately to get the access code so i show you EVERYTHING
          you wanna see on my <span>Telegram Channel!</span>😈
        </p>
        <div className="logoDiv1">
          <a
            href="https://www.tiktok.com/@thisisrayleneamoure?_t=8hNPQYhvL91&_r=1"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={tiktok} alt="" className="footerIcons1" />
          </a>
          <a
            href="mailto:thisisraylene@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={gmail} alt="" className="footerIcons1" />
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=61553759360168&mibextid=LQQJ4d"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={face} alt="" className="footerIcons1" />
          </a>
          <a
            href="https://instagram.com/thisisrayleneamoure?igshid=eHQ5bGpndDduMDB5&utm_source=qr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={insta} alt="" className="footerIcons1" />
          </a>
        </div>
        <div className="codeBoxs">
        <div className="codeBox">
          <label htmlFor="codeInput" className="codeLabel">
            Enter access code
          </label>
          <input
            type="text"
            id="codeInput"
            className="codeInput"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
        </div>
        <button onClick={handleButtonClick} className="codeBtn">
          Get Telegram Link
        </button>
       </div>
        {error && <p style={{ color: "red" }}>{error}</p>}
      </div>
    </div>
  );
};

export default Vip;
