import React from "react";
import "./LandingPage.css";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";

const LandingPage = () => {
  return (
    <div className="landingPage">
      <div className="Nav">
        <Navbar />
      </div>
      <div>
        {/* <img src={disdis} alt="" className="landingPicture" /> */}
        <div className="enterDiv">
          <p className="landingP">
            Please be advised that this website contains content and images not
            suitable for minors. If you are under the age of 18 or are offended
            by adult-oriented websites, please browse elsewhere. By choosing to
            continue past this disclaimer, you release and discharge the owner
            of this website and all parties involved with the creation,
            maintenance and hosting of any and all liability which may arise
            from your actions.
          </p>
          <Link to="/introduction">
            <button>Enter</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
