import React from "react";
import "./Visuals.css";
import Navbar from "../../components/Navbar";
import { FaCrown } from "react-icons/fa";
import { CiUnlock } from "react-icons/ci";
import { IoIosVideocam } from "react-icons/io";
import { Link } from "react-router-dom";

const Visuals = () => {
  return (
    <div className="visualPage">
      <div className="Nav">
        <Navbar />
      </div>
      <div className="visualBox">
        <h1 className="visualTxt">@thisisraylene</h1>
        <div className="visualsBtnBox">
          <Link className="Link visualsBtn" to="/vip">
            <CiUnlock className="unlock" />
            VIP OF <FaCrown />
          </Link>
          <Link className="Link visualsBtn" to="/free">
            <CiUnlock className="unlock" />
            FREE OF <IoIosVideocam />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Visuals;
